import { configureStore } from '@reduxjs/toolkit';
import authSlice from './Slice/authSlice';
import shopSlice from './Slice/shopSlice';
import customerSlice from './Slice/customerSlice';
import deliverySlice from './Slice/deliverySlice';
import productSlice from './Slice/productSlice';
import flavorSlice from './Slice/flavorSlice';
import categorySlice from './Slice/categorySlice';
import promoSlice from './Slice/promoSlice';
import notificationSlice from './Slice/notificationSlice';
import commSlice from './Slice/commSlice';
import ordersSlice from './Slice/ordersSlice';

const store = configureStore({
    reducer: {
        authData: authSlice,
        shop: shopSlice,
        customer: customerSlice,
        delivery: deliverySlice,
        product: productSlice,
        flavor: flavorSlice,
        category: categorySlice,
        promo: promoSlice,
        notify: notificationSlice,
        comm: commSlice,
        order: ordersSlice
    }
});

export default store;