import { createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../../service/Api";
import { constructQueryString } from "../../utils/CommonFunction";

const CustomerListAction = createAsyncThunk(
    'customer/list', async (reqBody, thunkAPI) => {
        try {
            const queryString = constructQueryString(reqBody);
            const response = await Api.get(`/users${queryString && `?${queryString}`}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

const CustomerDetailsAction = createAsyncThunk(
    'customer/details', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.get(`/users/${reqBody.id}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

const CustomerDeleteAction = createAsyncThunk(
    'customer/delete', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.delete(`/users/${reqBody}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

const CustomerSuspendAction = createAsyncThunk(
    'customer/suspend', async (reqBody, thunkAPI) => {
        try {
            const { id, ...payload } = reqBody;
            const response = await Api.patch(`/users/manage/${id}`, payload)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })


export {
    CustomerListAction,
    CustomerDetailsAction,
    CustomerDeleteAction,
    CustomerSuspendAction

};