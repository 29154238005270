import { createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../../service/Api";
import { constructQueryString } from "../../utils/CommonFunction";

const verifyProductListAction = createAsyncThunk(
    'verifyProduct/list', async (reqBody, thunkAPI) => {
        try {
            const { id, ...payload } = reqBody;
            const queryString = constructQueryString(payload);
            const response = await Api.get(`/shops/${id}/products?${queryString}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

const productListAction = createAsyncThunk(
    'product/list', async (reqBody, thunkAPI) => {
        try {
            const queryString = constructQueryString(reqBody);
            const response = await Api.get(`/products${queryString ? `?${queryString}` : ""}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

const verifyProductDetailsAction = createAsyncThunk(
    'verifyProduct/details', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.get(`/products/${reqBody.id}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

const ProductVerifyAction = createAsyncThunk(
    'product/verify', async (reqBody, thunkAPI) => {
        try {
            const { id, ...payload } = reqBody;
            const response = await Api.patch(`/products/${id}`, payload)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

const productDeleteAction = createAsyncThunk(
    'product/delete', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.delete(`/products/${reqBody}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

const EditProductAction = createAsyncThunk(
    'product/edit', async (reqBody, thunkAPI) => {
        try {
            console.log("reqBody", reqBody)
            const response = await Api.put(`/products/${reqBody.id}`, reqBody.formdata)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })



export {
    verifyProductListAction,
    verifyProductDetailsAction,
    ProductVerifyAction,
    productListAction,
    productDeleteAction,
    EditProductAction
};