import React, { Suspense, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Sidebar from '../Layout/Sidebar';

const PrivateRoutesSuspense = ({ children }) => {
  const [show, setShow] = useState(false);
  const [isActive, setIsActive] = useState(false);
  return (
    <>
      <div>
        <div className="main-wrap">
          <div className={show ? "left-side hide" : "left-side "}>
            <Sidebar
              isActive={isActive}
              setIsActive={setIsActive}
              show={show}
              setShow={setShow}
            />
          </div>
          <Suspense
            fallback={
              <div
                style={{
                  width: "110%",
                  height: "100%",
                  zIndex: "1",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  // backgroundColor: "rgba(0,0,0,0.1)",
                }}
              >
                <div class="loader"></div>
              </div>
            }
          // fallback={<div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
          //   <Spinner animation="grow" variant="primary" />
          //   <Spinner animation="grow" variant="secondary" />
          //   <Spinner animation="grow" variant="success" />
          //   <Spinner animation="grow" variant="danger" />
          //   <Spinner animation="grow" variant="warning" />
          //   <Spinner animation="grow" variant="info" />
          //   <Spinner animation="grow" variant="dark" />
          // </div>}
          >
            <div className={show ? "right-side-cmn hide" : "right-side-cmn "}>
              {children}
            </div>
          </Suspense>
        </div>
      </div>
    </>

  );
};

export default PrivateRoutesSuspense;
