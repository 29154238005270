import { createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../../service/Api";
import { constructQueryString } from "../../utils/CommonFunction";

const commListAction = createAsyncThunk(
    'comm/list', async (reqBody, thunkAPI) => {
        try {
            const queryString = constructQueryString(reqBody);
            const response = await Api.get(`/commission${queryString ? `?${queryString}` : ""}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

const commDetailsAction = createAsyncThunk(
    'comm/details', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.get(`/commission/${reqBody.id}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

const addCommAction = createAsyncThunk(
    'comm/add', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.post(`/commission`, reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

const updateCommAction = createAsyncThunk(
    'comm/edit', async (reqBody, thunkAPI) => {
        try {
            console.log("reqBody", reqBody)
            const { id, ...payload } = reqBody;
            const response = await Api.patch(`/commission/${id}`, payload)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })


export {
    commListAction,
    commDetailsAction,
    addCommAction,
    updateCommAction
};