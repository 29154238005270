import { createSlice } from "@reduxjs/toolkit";
import { CustomerDetailsAction, CustomerListAction, CustomerSuspendAction } from "../Action/CustomerAction";
const initialState = {
    customerList: [],
    customerDetails: {},
    customerPassStatus: "idle"
}

const customerSlice = createSlice({
    name: 'customer',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(CustomerListAction.fulfilled, (state, action) => {
                state.customerList = action?.payload;
            })
            .addCase(CustomerDetailsAction.fulfilled, (state, action) => {
                console.log("slice  action detail", action.payload)
                state.customerDetails = action?.payload;
            })
            .addCase(CustomerSuspendAction.fulfilled, (state, action) => {
                state.customerPassStatus = 'succeeded';
            })
    },
});

export default customerSlice.reducer;