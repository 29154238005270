import { createSlice } from "@reduxjs/toolkit";
import { dashboardAction, loginAction } from "../Action/AuthAction";
const initialState = {
    authData: {},
    dashboardData: {}
}

const authSlice = createSlice({
    name: 'authentication',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(loginAction.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(loginAction.fulfilled, (state, action) => {
                state.loading = false;
                state.authData = action?.payload?.data;
                // state.exampleData.push(action.payload);
            })
            .addCase(loginAction.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(dashboardAction.fulfilled, (state, action) => {
                state.dashboardData = action?.payload?.data;
                state.error = action.payload;
            });
    },
});

export default authSlice.reducer;