import { createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../../service/Api";
import { constructQueryString } from "../../utils/CommonFunction";

const shopListAction = createAsyncThunk(
    'shop/list', async (reqBody, thunkAPI) => {
        try {
            const queryString = constructQueryString(reqBody);
            const response = await Api.get(`/shops?${queryString}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

const shopVerifyAction = createAsyncThunk(
    'shop/verify', async (reqBody, thunkAPI) => {
        try {
            const { id, ...payload } = reqBody;
            const response = await Api.put(`/shops/${id}/verify`, payload)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

const ShopSuspendAction = createAsyncThunk(
    'shop/suspend', async (reqBody, thunkAPI) => {
        try {
            const { id, ...payload } = reqBody;
            const response = await Api.put(`/shops/${id}/suspend-active`, payload)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })


const shopDeleteAction = createAsyncThunk(
    'shop/delete', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.delete(`/shops/${reqBody}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })


const shopDetailsAction = createAsyncThunk(
    'shop/details', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.get(`/shops/${reqBody.id}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

const EditShopAction = createAsyncThunk(
    'shop/edit', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.put(`/shops/${reqBody.id}`, reqBody.formdata)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

export {
    shopListAction,
    shopDeleteAction,
    shopDetailsAction,
    shopVerifyAction,
    ShopSuspendAction,
    EditShopAction
};









