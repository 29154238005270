import { createSlice } from "@reduxjs/toolkit";
import { DeliveryDetailsAction, DeliveryListAction, DeliverySuspendAction, getDeliveryChargesAction } from "../Action/DeliveryAction";

const initialState = {
    deliveryList: [],
    deliveryDetails: {},
    deliveryChargesData: {},
    deliveryPassStatus: "idle"

}

const deliverySlice = createSlice({
    name: 'delivery',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(DeliveryListAction.fulfilled, (state, action) => {
                state.deliveryList = action?.payload;
            })
            .addCase(DeliveryDetailsAction.fulfilled, (state, action) => {
                state.deliveryDetails = action?.payload;
            })
            .addCase(DeliverySuspendAction.fulfilled, (state, action) => {
                state.deliveryPassStatus = 'succeeded'
            })
            .addCase(getDeliveryChargesAction.fulfilled, (state, action) => {
                state.deliveryChargesData = action?.payload;
            })
    },
});

export default deliverySlice.reducer;