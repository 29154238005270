import { createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../../service/Api";
import { constructQueryString } from "../../utils/CommonFunction";

const flavorListAction = createAsyncThunk(
    'flavor/list', async (reqBody, thunkAPI) => {
        try {
            // const { id, ...payload } = reqBody;
            const queryString = constructQueryString(reqBody);
            const response = await Api.get(`/flavor?${queryString}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

const flavorAddAction = createAsyncThunk(
    'flavor/add', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.post(`/flavor`, reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

const updateFlavorAction = createAsyncThunk(
    'flavor/edit', async (reqBody, thunkAPI) => {
        try {
            console.log("reqBody", reqBody)
            const response = await Api.put(`/flavor/${reqBody.id}`, reqBody.formdata)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

const flavorDetailsAction = createAsyncThunk(
    'flavor/details', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.get(`/flavor/${reqBody.id}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

const flavorDeleteAction = createAsyncThunk(
    'flavor/delete', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.delete(`/flavor/${reqBody}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })


export {
    flavorListAction,
    flavorDetailsAction,
    flavorDeleteAction,
    flavorAddAction,
    updateFlavorAction
};