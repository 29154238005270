import { createSlice } from "@reduxjs/toolkit";
import { commDetailsAction, commListAction } from "../Action/CommAction";

const initialState = {
    commList: [],
    commDetails: {},
    commPassStatus: "idle"
}

const commSlice = createSlice({
    name: 'comm',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(commListAction.fulfilled, (state, action) => {
                state.commList = action?.payload;
            })
            .addCase(commDetailsAction.fulfilled, (state, action) => {
                state.commDetails = action?.payload;
            });
           
    },
});

export default commSlice.reducer;