import { createSlice } from "@reduxjs/toolkit";
import { categoryAddAction, categoryDetailsAction, categoryListAction, subCategoryListAction } from "../Action/CategoryAction";

const initialState = {
    categoryList: [],
    subCategoryList: [],
    categoryDetails: {},
    categoryPassStatus: "idle"
}

const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(categoryListAction.fulfilled, (state, action) => {
                state.categoryList = action?.payload;
            })
            .addCase(subCategoryListAction.fulfilled, (state, action) => {
                state.subCategoryList = action?.payload;
            })
            .addCase(categoryDetailsAction.fulfilled, (state, action) => {
                state.categoryDetails = action?.payload;
            })
            .addCase(categoryAddAction.fulfilled, (state, action) => {
                state.categoryPassStatus = action?.payload;
            })
    },
});

export default categorySlice.reducer;