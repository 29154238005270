import { useState, useCallback, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { toast } from 'react-toastify';

const firebaseConfig = {
    apiKey: "AIzaSyB9KWy4f4plCraVxZ76OeMTty_2D_jWk9c",
    authDomain: "mikcakes-5be01.firebaseapp.com",
    projectId: "mikcakes-5be01",
    storageBucket: "mikcakes-5be01.appspot.com",
    messagingSenderId: "871841956868",
    appId: "1:871841956868:web:b84af98cb0900e2b1be151",
    measurementId: "G-V3QP01BF32"
  };

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

const useFirebaseToken = () => {
    const [token, setToken] = useState(null);

    const getFireBaseToken = useCallback(async () => {
        try {
            const response = await Notification.requestPermission();
            if (response === "granted") {
                console.log('Notification permission granted.');
                try {
                    const fetchedToken = await getToken(messaging, {
                        vapidKey: 'BM5tBcDmc-ZUlSIsElQIdYPVaUDFngk8B0DbOk1OfTsAhtj-0AN3VVILw3X2pKEIVmbD3ylLGM6T7x12E2bY6NA'
                    });
                    if (fetchedToken) {
                        setToken(fetchedToken);
                        console.log('FCM Token:', fetchedToken);
                        return fetchedToken;
                    } else {
                        console.log('No registration token available. Request permission to generate one.');
                    }
                } catch (error) {
                    console.error('An error occurred while retrieving token. ', error);
                }
            } else {
                console.log('Unable to get permission to notify.');
            }
        } catch (error) {
            console.error("Error requesting notification permission.", error);
        }
    }, []);

    // useEffect(() => {
    //     const unsubscribe = onMessage(messaging, (payload) => {
    //         console.log('Message received. ', payload);
    //         toast.info(`${payload.notification.title}: ${payload.notification.body}`);
    //     });
    //     return () => {
    //         unsubscribe();
    //     };
    // }, []);

    return { token, getFireBaseToken };
};

export default useFirebaseToken;
