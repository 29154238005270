import { createSlice } from "@reduxjs/toolkit";
import { notifyListAction } from "../Action/NotificationAction";

const initialState = {
    notifyList: []
}

const notificationSLice = createSlice({
    name: 'notify',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(notifyListAction.fulfilled, (state, action) => {
                state.notifyList = action?.payload;
            })
    },
});

export default notificationSLice.reducer;