import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = () => {
    const userToken = window.sessionStorage.getItem("token");
    if (userToken) {
        return <Outlet />;
    } else {

        return <Navigate to={"/"} />;
    }
};

export default ProtectedRoute;
