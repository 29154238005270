// PublicRoutesSuspense.js
import React, { Suspense } from 'react';
import { Spinner } from 'react-bootstrap';

const PublicRoutesSuspense = ({ children }) => (
  <Suspense
    fallback={
      <div
        style={{
          width: "110%",
          height: "100%",
          zIndex: "1",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          // backgroundColor: "rgba(0,0,0,0.1)",
        }}
      >
        <div class="loader"></div>
      </div>
    }
  // fallback={
  // <div style={{position:"absolute", top:"50%",left:"50%", transform:"translate(-50%, -50%)"}}>
  //   <Spinner animation="grow" variant="primary" />
  //   <Spinner animation="grow" variant="secondary" />
  //   <Spinner animation="grow" variant="success" />
  //   <Spinner animation="grow" variant="danger" />
  //   <Spinner animation="grow" variant="warning" />
  //   <Spinner animation="grow" variant="info" />
  //   <Spinner animation="grow" variant="dark" />
  //   </div>
  // }
  >
    {children}
  </Suspense>
);

export default PublicRoutesSuspense;
