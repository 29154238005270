import { createSlice } from "@reduxjs/toolkit";
import { productListAction, verifyProductDetailsAction, verifyProductListAction } from "../Action/ProductAction";

const initialState = {
    productList: [],
    verfProductList: [],
    productDetails: {},
    productPassStatus: "idle"
}

const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(verifyProductListAction.fulfilled, (state, action) => {
                state.productList = action?.payload;
            })
            .addCase(verifyProductDetailsAction.fulfilled, (state, action) => {
                state.productDetails = action?.payload;
            })
            .addCase(productListAction.fulfilled, (state, action) => {
                state.verfProductList = action?.payload;
            })
    },
});

export default productSlice.reducer;