import { createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../../service/Api";
import { constructQueryString } from "../../utils/CommonFunction";

const notifyListAction = createAsyncThunk(
    'notify/list', async (reqBody, thunkAPI) => {
        try {
            const queryString = constructQueryString(reqBody);
            const response = await Api.get(`/notification${queryString ? `?${queryString}` : ""}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

const deleteNotifyAction = createAsyncThunk(
    'notify/delete', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.delete(`/notification/${reqBody}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

const createNotifyAction = createAsyncThunk(
    'notify/add', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.post(`/notification`, reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

export {
    notifyListAction,
    createNotifyAction,
    deleteNotifyAction,
};