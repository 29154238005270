import { createSlice } from "@reduxjs/toolkit";
import { shopDeleteAction, shopDetailsAction, shopListAction } from "../Action/ShopAction";
const initialState = {
    shopList: [],
    shopDetails: {},
    shopPassStatus: "idle"
}

const shopSlice = createSlice({
    name: 'shop',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(shopListAction.fulfilled, (state, action) => {
                state.shopList = action?.payload;
            })
            .addCase(shopDetailsAction.fulfilled, (state, action) => {
                console.log("slice  action detail", action.payload)
                state.shopDetails = action?.payload;
            })
            .addCase(shopDeleteAction.fulfilled, (state, action) => {
                state.shopPassStatus = 'succeeded';
            })
    },
});

export default shopSlice.reducer;