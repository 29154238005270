import { createSlice } from "@reduxjs/toolkit";
import { promoDetailsAction, promoListAction } from "../Action/PromoAction";

const initialState = {
    promoList: [],
    promoDetails: {},
    promoPassStatus: "idle"
}

const promoSlice = createSlice({
    name: 'promo',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(promoListAction.fulfilled, (state, action) => {
                state.promoList = action?.payload;
            })
            .addCase(promoDetailsAction.fulfilled, (state, action) => {
                state.promoDetails = action?.payload;
            });
           
    },
});

export default promoSlice.reducer;