import { createSlice } from "@reduxjs/toolkit";
import { flavorAddAction, flavorDetailsAction, flavorListAction } from "../Action/FlavorAction";

const initialState = {
    flavorList: [],
    flavorDetails: {},
    flavorPassStatus: "idle"
}

const flavorSlice = createSlice({
    name: 'flavor',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(flavorListAction.fulfilled, (state, action) => {
                state.flavorList = action?.payload;
            })
            .addCase(flavorDetailsAction.fulfilled, (state, action) => {
                state.flavorDetails = action?.payload;
            })
            .addCase(flavorAddAction.fulfilled, (state, action) => {
                state.flavorPassStatus = action?.payload;
            })
    },
});

export default flavorSlice.reducer;